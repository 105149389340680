import React from 'react';

export class Disclaimer extends React.Component {
    render() {
        return (
            <div className="standard-container">
                <div className="standard-inner-container body-inner-width" style={{margin:0}}>
                    <hr />
                    <p className="disclaimer">{this.props.disclaimer}</p>
                </div>
            </div>
        )
    }
}