import * as React from "react"
import { Navigation } from "../components/global/nav"
import { HomeBody } from "../components/local/home/homeBody"
import "../styles.css"
import { Footer } from '../components/global/footer'
import SEO from '../components/global/seo'
import CookiePrompt from '../components/global/cookies-prompt';

// styles
const pageStyles = {
  color: "#232129",
  fontFamily: "poppins, sans-serif",
  fontSize: 16
}

// markup
const IndexPage = () => {
  return (
    <main style={pageStyles}>
      <SEO
          title="Smart Plan - The smart way to cover your home"
          slug=""
          metadescription="Smart Plan is the UK's new favourite home cover provider, with fully customised plans with no one-size-fits-all. Don't pay for what you don't use!"
          image=""
          titleTemplate="%s"
      />
      <CookiePrompt/>
      <Navigation/> 
      <HomeBody/>
      <Footer />
    </main>
  )
}

export default IndexPage
