import React from 'react';
import { FaSearch as Search, FaCheckCircle as CircleCheck, FaMugHot as Mug, FaArrowRight as ArrowRight, FaCheckSquare as Check } from "react-icons/fa";
import { HelpGuides } from '../../global/virtual-help';
import { Disclaimer } from '../../global/disclaimer';

class HeroSection extends React.Component {
    render() {
        return (
            <React.Fragment>
                <div className="standard-title-container home">
                    <div className="standard-title-inner-container body-inner-width">
                        <div className="standard-title-inner-content">
                            <h1>The smart way to cover your home.</h1>

                            <p>Don't pay for one-size-fits-all plans that aren't made for you. With Smart&nbsp;Plan, you are in control.</p>
                            
                            <div className="double-button-group">
                                <a
                                    className="large-button green-button"
                                    href="/join"
                                >
                                    Get covered &nbsp;
                                    <Check/>
                                </a>
                                <a href="/account">Manage your account</a>
                            </div>

                            <span id="tp-home-rating">
                                <img 
                                    src="./images/trustpilot-5stars.png"
                                    alt="Smart Plan Trustpilot five stars"
                                    title="Smart Plan are voted 5 stars on Trustpilot"
                                />&nbsp;
                                <p style={{margin: 0}}>Voted "<strong>Excellent</strong>"</p>
                            </span>
                            <p style={{fontSize: 14,marginTop: 5}}
                            >We've helped over 15,000 happy customers keep their house a home.</p>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

class HomeSteps extends React.Component {
    render() {
        return (
            <React.Fragment>
                <div className="standard-container">
                    <div className="standard-inner-container body-inner-width">
                        <center><h2>3 steps to your Smart Plan</h2></center>

                        <div className="three-card-container">
                            <div className="standard-card blue-card">
                                <Search />
                                <h4>Browse our plans</h4>
                                <p>Browse through our four complete packages or look through the individual packages that could make up your custom Smart Plan.</p>
                            </div>

                            <div className="standard-card pink-card">
                                <CircleCheck style={{color: '#fa4590'}}/>
                                <h4>Choose your coverage</h4>
                                <p>Choose the coverage that suits your needs, don't settle for premade packages if they don't fit you!</p>
                            </div>

                            <div className="standard-card green-card">
                                <Mug style={{color: '#8bc34a'}}/>
                                <h4>Relax, you're covered</h4>
                                <p>Now you can relax with the peace of mind that if anything were to happen to your home, you're covered by your new Smart Plan.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

class HomeProducts extends React.Component {
    render() {
        return (
            <React.Fragment>
                <div className="home-products-container">
                    <div className="home-products-inner-container body-inner-width">
                        <h2>Stay because you <span style={{color: '#fa4590'}}>want</span> to...<br/><span style={{fontWeight: 'normal'}}>Not because you have to.</span></h2>

                        <p>As standard, all Smart Plan cover plans come with a 30-day rolling term, so if your plan doesn't suit your needs anymore, don't worry, you can cancel anytime with just 30 days notice and no fee to pay.* Stay because you want to, not because you have to.</p>

                        <div className="double-button-group">
                            <a
                                className="large-button green-button"
                                href="/join"
                            >
                                Get covered &nbsp;
                                <Check/>
                            </a>
                            <a href="/account">Manage your account</a>
                        </div>
                    </div>
                </div>
                <div className="home-products-container-rounded"></div>
                <div className="standalone-container-home-products">
                    <div className="two-card-container body-inner-width">
                        <div class="standard-card pink-card product-card">
                            <span>
                                <h3>Boiler cover plans</h3>
                                <p>Take care of your boiler and avoid those pricey repairs, or even worse, a new boiler! Let us take care of everything - we'll even come and assess your boiler each year to check everything is in order!</p>   
                            </span>
                            <a
                                className="product-card-button"
                                href="/join"
                            >
                                <span>Cover your boiler</span>
                                <ArrowRight />
                            </a>
                            
                        </div>
                        <div class="standard-card pink-card product-card">
                            <span>
                                <h3>New boiler installations</h3>
                                <p>Sometimes a repair just isn't enough and a new boiler is in order. We have a number of options for you to choose from so you can spread the cost of your new boiler.</p>
                            </span>
                            <a
                                className="product-card-button"
                                href="/new-boiler"
                            >
                                <span>View new boilers</span>
                                <ArrowRight />
                            </a>
                        </div>
                        <div class="standard-card pink-card product-card">
                        <span>
                                <h3>Home emergency</h3>
                                <p>Emergencies in your home aren't always about your boiler, that's why we help our customers cover a wide variety of core elements of your home. That way whatever happens, we'll be there.</p>
                            </span>
                            <a
                                className="product-card-button"
                                href="/join"
                            >
                                <span>Cover your home</span>
                                <ArrowRight />
                            </a>
                        </div>
                        <div class="standard-card pink-card product-card">
                        <span>
                                <h3>Appliance cover</h3>
                                <p>Today, we can't live without our technology. With Smart Plan, you don't have to. If something happens to one of your devices, we'll be there to get your tech back in your hand in good working order.</p>
                            </span>
                            <a
                                className="product-card-button"
                                href="/join"
                            >
                                <span>Cover your appliances</span>
                                <ArrowRight />
                            </a>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export class HomeBody extends React.Component {
    render() {
        return(
            <React.Fragment>
                <HeroSection/>
                <HomeSteps/>
                <HomeProducts/>
                <HelpGuides title="Need some virtual help?"/>
                <Disclaimer disclaimer="* Terms & conditions apply. 30 day rolling contract triggers 12 month minimum term contract once claim is made and restarts 12 month contractual period after every successive claim. Read our terms & conditions for more details." />
            </React.Fragment>
            
        )
    }
}