import React from 'react';
import { FaCheckSquare as Check} from "react-icons/fa";

export class HelpGuides extends React.Component {
    render() {
        return (
            <React.Fragment>
                <div className="standard-container">
                    <div className="standard-inner-container body-inner-width">
                        <center>
                            <h2>{this.props.title}</h2>
                            <p className="half-width-text">At Smart Plan we give you all of the tools that you need to avoid and solve home emergency issues without needing to call out an engineer. You are in control!</p>
                        </center>

                        <div className="split-50-container" id="help-guides-container">
                            <img
                                src="./images/guides-cover.png"
                                alt="Smart Plan Boiler engineer"
                                title="Smart Plan guides for boilers, heating & more"
                            />
                            <span>
                                <h3>Visit our how-to guides</h3>
                                <p>We produce easy to understand content and guides so that our customers have complete control over how they manage their home. For help on the following topics...</p>

                                <ul className="check-list">
                                    <li>Radiators</li>
                                    <li>Pipes & drains</li>
                                    <li>Boilers</li>
                                    <li>Much much more</li>
                                </ul>
                                <br/>
                                <a
                                    className="large-button blue-button"
                                    href="/guides"
                                >
                                    Get started &nbsp;
                                    <Check/>
                                </a>
                            </span>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}